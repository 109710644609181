// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DefaultLayout_layout-box-container__ICp2n{display:flex}.DefaultLayout_layout-bar-app__xPc2L{margin:0}.DefaultLayout_layout-icon-button__2I-75{margin-right:.625rem}.DefaultLayout_layout-box-main__I5KWx{flex-grow:1;padding:.5rem;margin-top:3.5rem}@media only screen and (min-width: 600px){.DefaultLayout_layout-bar-app__xPc2L{width:calc(100% - 12.5rem) !important;margin-left:12.5rem}.DefaultLayout_layout-icon-button__2I-75{display:none !important}.DefaultLayout_layout-box-main__I5KWx{width:calc(100% - 12.5rem)}}`, "",{"version":3,"sources":["webpack://./src/layouts/DefaultLayout/DefaultLayout.module.scss"],"names":[],"mappings":"AAEA,2CACE,YAAA,CAGF,qCACE,QAAA,CAGF,yCACE,oBAAA,CAGF,sCACC,WAAA,CACA,aAAA,CACC,iBAAA,CAGF,0CACA,qCACC,qCAAA,CACA,mBAvBe,CA0BhB,yCACE,uBAAA,CAGF,sCACE,0BAAA,CAAA","sourcesContent":["$width_navbar : 12.5rem;\n\n.layout-box-container {\n  display: flex;\n}\n\n.layout-bar-app {\n  margin: 0;\n}\n\n.layout-icon-button{\n  margin-right: 0.625rem;\n}\n\n.layout-box-main {\n\tflex-grow: 1;\n\tpadding: 0.5rem;\n  margin-top: 3.5rem;\n}\n\n@media only screen and (min-width:600px) {\n.layout-bar-app {\n\twidth: calc(100% - $width_navbar) !important;\n\tmargin-left: $width_navbar;  \n}\n\n.layout-icon-button{\n  display: none !important;\n}\n\n.layout-box-main {\n  width: calc(100% - $width_navbar);\n}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout-box-container": `DefaultLayout_layout-box-container__ICp2n`,
	"layout-bar-app": `DefaultLayout_layout-bar-app__xPc2L`,
	"layout-icon-button": `DefaultLayout_layout-icon-button__2I-75`,
	"layout-box-main": `DefaultLayout_layout-box-main__I5KWx`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DrawerNav_nav-drawer-temporary__b\\+P7-,.DrawerNav_nav-drawer-permanent__CXKHo{box-sizing:border-box;width:12.5rem}.DrawerNav_nav-drawer-temporary__b\\+P7- img,.DrawerNav_nav-drawer-permanent__CXKHo img{max-width:9.5rem}.DrawerNav_nav-button-anchor__4EW9Q{color:#000}@media only screen and (max-width: 599px){.DrawerNav_nav-drawer-temporary__b\\+P7-{display:block}.DrawerNav_nav-drawer-permanent__CXKHo{display:none}}@media only screen and (min-width: 600px){.DrawerNav_box-nav__xFNWO{width:12.5rem;flex-shrink:0}.DrawerNav_nav-drawer-temporary__b\\+P7-{display:none}.DrawerNav_nav-drawer-permanent__CXKHo{display:block}}`, "",{"version":3,"sources":["webpack://./src/components/DrawerNav/DrawerNav.module.scss"],"names":[],"mappings":"AAEA,+EACC,qBAAA,CACA,aAJe,CAKf,uFACC,gBAAA,CAIF,oCACC,UAAA,CAGD,0CACC,wCACC,aAAA,CAGD,uCACC,YAAA,CAAA,CAIF,0CACA,0BACC,aA1Be,CA2Bf,aAAA,CAGD,wCACC,YAAA,CAGD,uCACC,aAAA,CAAA","sourcesContent":["$width_navbar : 12.5rem;\n\n.nav-drawer-temporary, .nav-drawer-permanent {\n\tbox-sizing:border-box;\n\twidth: $width_navbar;\n\timg {\n\t\tmax-width: 9.5rem;\n\t}\n}\n\n.nav-button-anchor {\n\tcolor:black;\n}\n\n@media only screen and (max-width: 599px) {\n\t.nav-drawer-temporary {\n\t\tdisplay: block;\n\t}\n\n\t.nav-drawer-permanent {\n\t\tdisplay: none;\n\t}\n}\n\n@media only screen and (min-width:600px) {\n.box-nav {\n\twidth: $width_navbar;\n\tflex-shrink: 0;\n}\n\n.nav-drawer-temporary {\n\tdisplay: none;\n}\n\n.nav-drawer-permanent {\n\tdisplay: block;\t\n}\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav-drawer-temporary": `DrawerNav_nav-drawer-temporary__b+P7-`,
	"nav-drawer-permanent": `DrawerNav_nav-drawer-permanent__CXKHo`,
	"nav-button-anchor": `DrawerNav_nav-button-anchor__4EW9Q`,
	"box-nav": `DrawerNav_box-nav__xFNWO`
};
export default ___CSS_LOADER_EXPORT___;

import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import DefaultLayout from 'layouts/DefaultLayout';
import InformesListContainer from 'pages/InformesListContainer';
import Loading from 'components/Loading';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ErrorFallback';
import aseguradoraService from 'services/aseguradoraService/aseguradoraService';
import { setItem, getItem } from 'services/sessionStorageService';
import Dashboard from 'pages/Dashboard';
import Reportes from 'pages/Reportes';
import PrestadoresHabilitadosListContainer from 'pages/PrestadoresHabilitadosListContainer';
import { iniciarMouseFlow } from 'services/mouseFlow/mouseFlow';
import browserLang from 'browser-lang';
import LoginForm from './pages/LoginForm';
import Informe from './pages/Informe';
import messages from './i18n';

const KEY_ASEGURADORA_LOGGED =
  process.env.REACT_APP_KEY_ASEGURADORA_LOGGED ?? '';

const INICIAR_MOUSEFLOW = !!process.env.REACT_APP_INICIAR_MOUSEFLOW;
const URL_MOUSEFLOW = process.env.REACT_APP_URL_MOUSEFLOW ?? '';

const supportedLanguages = ['es', 'pt'];

const App: React.FC = () => {
  const defaultLocale = browserLang({
    languages: supportedLanguages,
    fallback: 'en',
  });

  const [isLogged, setIsLogged] = useState<boolean>(false);
  const { data: aseguradoraData } = useQuery(
    ['aseguradora'],
    aseguradoraService.getAseguradora,
    { staleTime: Infinity, enabled: isLogged },
  );

  useEffect(() => {
    const aseguradoraNombre = getItem(KEY_ASEGURADORA_LOGGED);
    if (aseguradoraNombre !== null) {
      setIsLogged(true);
    }

    if (INICIAR_MOUSEFLOW) {
      iniciarMouseFlow(URL_MOUSEFLOW);
    }
  }, []);

  useEffect(() => {
    if (aseguradoraData) {
      setItem(KEY_ASEGURADORA_LOGGED, aseguradoraData.razon_social);
    }
  }, [aseguradoraData]);

  return (
    <IntlProvider
      locale={defaultLocale}
      messages={messages[defaultLocale as 'es' | 'pt']}
      defaultLocale={defaultLocale}
    >
      <Router>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route element={<DefaultLayout />}>
            <Route
              path="/informes"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loading />}>
                    <InformesListContainer />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/informes/:idInforme/:uuidInforme"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loading />}>
                    <Informe />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Dashboard />
                </ErrorBoundary>
              }
            />
            <Route
              path="/reportes"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loading />}>
                    <Reportes />
                  </Suspense>
                </ErrorBoundary>
              }
            />
            <Route
              path="/prestadoreshabilitados"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Loading />}>
                    <PrestadoresHabilitadosListContainer />
                  </Suspense>
                </ErrorBoundary>
              }
            />
          </Route>
        </Routes>
      </Router>
    </IntlProvider>
  );
};

export default App;

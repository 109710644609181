// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PresupuestosRankingPrestadores_th-color__3ndsh{background-color:#81d4fa}.PresupuestosRankingPrestadores_tr-total__7r7HC{background-color:#e1f5fe}.PresupuestosRankingPrestadores_tr-total__7r7HC .PresupuestosRankingPrestadores_tc-col__RzSPK{font-weight:bold}.PresupuestosRankingPrestadores_tc-first-col__tyAfw{padding-left:2.5rem;font-size:.25rem}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/PresupuestosRankingPrestadores/PresupuestosRankingPrestadores.module.scss"],"names":[],"mappings":"AAAA,gDACC,wBAAA,CAED,gDACC,wBAAA,CAEA,8FACC,gBAAA,CAGF,oDACC,mBAAA,CACA,gBAAA","sourcesContent":[".th-color {\n\tbackground-color: #81d4fa;\n}\n.tr-total {\n\tbackground-color: #e1f5fe;\n\n\t.tc-col {\t\n\t\tfont-weight:bold;\n\t}\n}\n.tc-first-col {\n\tpadding-left: 2.5rem;\n\tfont-size: .25rem;\t\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"th-color": `PresupuestosRankingPrestadores_th-color__3ndsh`,
	"tr-total": `PresupuestosRankingPrestadores_tr-total__7r7HC`,
	"tc-col": `PresupuestosRankingPrestadores_tc-col__RzSPK`,
	"tc-first-col": `PresupuestosRankingPrestadores_tc-first-col__tyAfw`
};
export default ___CSS_LOADER_EXPORT___;

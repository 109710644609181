// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InformesListView_divider-title__RNMXb{background:#42a5f5;height:.25rem}.InformesListView_divider-row__8SPfY{background:#bdbdbd}.InformesListView_paper-search__Q-tHS{display:flex;align-items:center;padding:.3rem .5rem;margin:.5rem 0}.InformesListView_input-base-search__8tl55{flex:1;margin-left:.25rem}.InformesListView_input-divider-search__0ne0P{height:2rem;margin:0 .25rem}`, "",{"version":3,"sources":["webpack://./src/components/InformesListView/InformesListView.module.scss"],"names":[],"mappings":"AAAA,uCACE,kBAAA,CACA,aAAA,CAEF,qCACE,kBAAA,CAEF,sCACE,YAAA,CACA,kBAAA,CACA,mBAAA,CACA,cAAA,CAEF,2CACE,MAAA,CACA,kBAAA,CAEF,8CACE,WAAA,CACA,eAAA","sourcesContent":[".divider-title {\r\n  background: #42a5f5;\r\n  height: 0.25rem;\r\n}\r\n.divider-row {\r\n  background: #bdbdbd;\r\n}\r\n.paper-search {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 0.3rem .5rem;\r\n  margin: 0.5rem 0;  \r\n}\r\n.input-base-search {\r\n  flex: 1;\r\n  margin-left: .25rem;\r\n}\r\n.input-divider-search {\r\n  height: 2rem;\r\n  margin: 0 .25rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider-title": `InformesListView_divider-title__RNMXb`,
	"divider-row": `InformesListView_divider-row__8SPfY`,
	"paper-search": `InformesListView_paper-search__Q-tHS`,
	"input-base-search": `InformesListView_input-base-search__8tl55`,
	"input-divider-search": `InformesListView_input-divider-search__0ne0P`
};
export default ___CSS_LOADER_EXPORT___;

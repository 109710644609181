// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoResults_alert-no-results__6m9mi{width:100%}.NoResults_box-logo__tI6kl{min-height:25vh}.NoResults_box-logo__tI6kl img{opacity:.25}`, "",{"version":3,"sources":["webpack://./src/components/NoResults/NoResults.module.scss"],"names":[],"mappings":"AAAA,mCACE,UAAA,CAGF,2BACE,eAAA,CACA,+BACE,WAAA","sourcesContent":[".alert-no-results{\n  width: 100%;\n}\n\n.box-logo {\n  min-height: 25vh;\n  img {\n    opacity: .25;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert-no-results": `NoResults_alert-no-results__6m9mi`,
	"box-logo": `NoResults_box-logo__tI6kl`
};
export default ___CSS_LOADER_EXPORT___;

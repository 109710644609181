import { defineMessages } from 'react-intl';

export default defineMessages({
  titleDrawerNav: {
    id: 'DrawerNav.titleDrawerNav',
    description: 'Titulo para el DrawerNav',
    defaultMessage: 'Appsolut',
  },
  dashboard: {
    id: 'DrawerNav.Dashboard',
    description: 'Opción Dashboard del DrawerNav',
    defaultMessage: 'Dashboard',
  },
  informes: {
    id: 'DrawerNav.informes',
    description: 'Opción Informes del DrawerNav',
    defaultMessage: 'Informes',
  },
  prestadores: {
    id: 'DrawerNav.prestadores',
    description: 'Opción Prestadores del DrawerNav',
    defaultMessage: 'Prestadores',
  },
  reportes: {
    id: 'DrawerNav.reportes',
    description: 'Opción Reportes del DrawerNav',
    defaultMessage: 'Reportes',
  },
  salir: {
    id: 'DrawerNav.salir',
    description: 'Opción Salir del DrawerNav',
    defaultMessage: 'Salir',
  },
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PresupuestosGraficoInformesPorEstados_grafico-estados-informes-container__h5BdL{height:25rem;width:25rem}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/PresupuestosGraficoInformesPorEstados/PresupuestosGraficoInformesPorEstados.module.scss"],"names":[],"mappings":"AAAA,iFACE,YAAA,CACA,WAAA","sourcesContent":[".grafico-estados-informes-container {\n  height: 25rem;\n  width: 25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grafico-estados-informes-container": `PresupuestosGraficoInformesPorEstados_grafico-estados-informes-container__h5BdL`
};
export default ___CSS_LOADER_EXPORT___;

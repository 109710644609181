// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorFallback_card-error__C8c19{max-width:40rem;margin:auto}.ErrorFallback_card-error-actions__RH9sh{justify-content:center;margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/components/ErrorFallback/ErrorFallback.module.scss"],"names":[],"mappings":"AAAA,iCACI,eAAA,CACA,WAAA,CAEJ,yCACI,sBAAA,CACA,kBAAA","sourcesContent":[".card-error {\r\n    max-width: 40rem;\r\n    margin: auto;\r\n}\r\n.card-error-actions {\r\n    justify-content: center;\r\n    margin-bottom: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-error": `ErrorFallback_card-error__C8c19`,
	"card-error-actions": `ErrorFallback_card-error-actions__RH9sh`
};
export default ___CSS_LOADER_EXPORT___;
